import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container } from "react-bootstrap";

import Error from "../../../../../components/utils/portal/error/Error";
import Loading from "../../../../../components/utils/portal/loading/Loading";
import { getDataPositiveImpact } from "../../../../../app/about/socialImpact/socialImpactSlice";
import { useTranslation } from "react-i18next";
import Title from "../../../../../components/title/Title";
import { Fade } from "react-reveal";

function PositiveImpact() {
  // fetch data
  const dispatch = useDispatch();
  const { isLoading, positiveImpact, error } = useSelector((state) => state.socialImpact);
  useEffect(() => {
    dispatch(getDataPositiveImpact());
  }, []);

  // change language handler
  const { i18n, t } = useTranslation();

  // loading
  if (isLoading) {
    return <Loading />;
  }
  // error
  if (error) {
    return <Error error={error.message} />;
  }
  return (
    <section className="PositiveImpact pb-4">
      <Container>
        <Title title={t("MakingPositiveImpact")} className="mainColor" />
        <Fade up duration={1800} delay={500}>
          <p>
            {`${
              i18n.language === "en" ? positiveImpact?.result?.descriptionEn : positiveImpact?.result?.descriptionAr
            }`}
          </p>
        </Fade>
      </Container>
    </section>
  );
}

export default PositiveImpact;
