import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import Error from "../../../../../components/utils/portal/error/Error";
import Loading from "../../../../../components/utils/portal/loading/Loading";
import { getDataSteps, getDataPositiveImpact2 } from "../../../../../app/about/socialImpact/socialImpactSlice";
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import StepsCard from "../../../../../components/cards/stepsCard/StepsCard";

function Steps() {
  // fetch data
  const dispatch = useDispatch();
  const { isLoading, steps, positiveImpact2, error } = useSelector((state) => state.socialImpact);
  useEffect(() => {
    dispatch(getDataSteps());
    dispatch(getDataPositiveImpact2());
  }, []);

  // change language handler
  const { i18n } = useTranslation();

  // loading
  if (isLoading) {
    return <Loading />;
  }
  // error
  if (error) {
    return <Error error={error.message} />;
  }
  return (
    <section className="steps pb-5">
      <Container>
        <Row className="mb-4">
          {steps?.result?.map((step) => {
            return (
              <Col lg={3} md={6} key={step?.id} className="mb-lg-0 mb-4">
                <StepsCard
                  img={step?.image}
                  number={step?.number}
                  title={`${i18n.language === "en" ? step?.titleEn : step?.titleAr}`}
                />
              </Col>
            );
          })}
        </Row>
        <Fade up duration={1800} delay={500}>
          <p className="mb-0">
            {`${
              i18n.language === "en" ? positiveImpact2?.result?.descriptionEn : positiveImpact2?.result?.descriptionAr
            }`}
          </p>
        </Fade>
      </Container>
    </section>
  );
}

export default Steps;
