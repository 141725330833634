import React from "react";
import style from "./numberBox.module.scss";
import Img from "../../utils/img/Img";

function NumberBox({ img, title, desc }) {
  return (
    <div className={style.numberBox}>
      {img && <Img className={style.numberBox_img} src={img} alt={title} />}
      {title &&  <h3 className={style.numberBox_title}>{title}</h3>}
      {desc &&  <p className={style.numberBox_desc}>{desc}</p>}

     
     
    </div>
  );
}

export default NumberBox;
