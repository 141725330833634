import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Error from "../../../../../../components/utils/portal/error/Error";
import Loading from "../../../../../../components/utils/portal/loading/Loading";
import { getDataProducts } from "../../../../../../app/solutions/k12/k12Slice";
import { useTranslation } from "react-i18next";
import HalfSection7 from "../../../../../../components/halfSection7/HalfSection7";
import {Contact3} from "../contact2/Contact2";

function Rehlatie() {
  // fetch data
  const dispatch = useDispatch();
  const { isLoading, products, error } = useSelector((state) => state.k12);
  useEffect(() => {
    dispatch(getDataProducts());
  }, []);

  // change language handler
  const { i18n, t } = useTranslation();

  // loading
  if (isLoading) {
    return <Loading />;
  }
  // error
  if (error) {
    return <Error error={error.message} />;
  }
  return (
    <>
      <section className="Rehlatie pb-4 pt-0" id="Rehlatie">
        {products?.result?.products.slice(2, 3)?.map((offer) => {
          return offer?.serviceCategoryServicesContents?.map((section) => {
            return (
              <div className=" whtBg mb-0" key={section.id}>
                <Container>
                  <HalfSection7
                    id={section.id}
                    title={section.titleEn !== null && `${i18n.language === "en" ? section.titleEn : section.titleAr}`}
                    desc={`${i18n.language === "en" ? section.descriptionEn : section.descriptionAr}`}
                    img={section.image}
                    className={"p-0 mb-lg-2 mb-4 whtBg"}
                    col1Lg={7}
                    col2Lg={5}
                    transparent={true}
                    href={section.titleEn}
                    ps={true}
                    ptb0={true}
                  />
                  {/* Move this mapping function outside of the return block */}
                  {section?.serviceContentListDetails?.map((box) => {
                    return (
                      <div key={box?.id}>
                        <h5 className="mb-3 black font-weight-bold">{`${i18n.language === "en" ? box.titleEn : box.titleAr}`}</h5>
                        <p>{`${i18n.language === "en" ? box.descriptionEn : box.descriptionAr}`}</p>
                      </div>
                    )
                  })}
                </Container>

              </div>
            );
          });
        })}


      </section >
      <Contact3 />
    </>
  );
}

export default Rehlatie;
