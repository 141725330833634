import React, { useEffect } from "react";
import Banner from "./components/banner/Banner";
import Services from "./components/services/Services";
import Products from "./components/products/Products";
import { Tab, Tabs } from "react-bootstrap";
import { useLocation } from "react-router-dom";


const HigherEducation = ({defaultActiveKey}) => {
  const { hash } = useLocation();

  useEffect(() => {
    const handlePageLoad = () => {
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };
    // Attach the handler to the window's load event
    window.addEventListener('load', handlePageLoad);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('load', handlePageLoad);
    };
  }, [hash])
  return (
    <>
      <Banner />
      <Tabs
        defaultActiveKey={defaultActiveKey === "Services" ? "Services" : "Products"}
        id="uncontrolled-tab-example"
      >
        <Tab eventKey="Services" title="Services">
          <Services />
        </Tab>
        <Tab eventKey="Products" title="Products">
          <Products />
        </Tab>

      </Tabs>


    </>
  );
};

export default HigherEducation;
