import { lazy, useEffect } from "react";
import Contact2 from "./components/contact2/Contact2";
import { Tab, Tabs } from "react-bootstrap";
import { useLocation } from "react-router-dom";
const Banner = lazy(() => import("./components/banner/Banner"));
const Books = lazy(() => import("./components/books/Books"));
const Printing = lazy(() => import("./components/printing/Printing"));
const Contact = lazy(() => import("./components/contact/Contact"));


const Publishing = ({defaultActiveKey}) => {
  const { hash } = useLocation();

  useEffect(() => {
    const handlePageLoad = () => {
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };
    // Attach the handler to the window's load event
    window.addEventListener('load', handlePageLoad);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('load', handlePageLoad);
    };
  }, [hash])
  return (
    <>
      <Banner />
      <Tabs
        defaultActiveKey={defaultActiveKey === "Services" ? "Services" : "Products"}
        id="uncontrolled-tab-example"
      >
        <Tab eventKey="Services" title="Services">
          <Printing />
          <Contact />
        </Tab>
        <Tab eventKey="Products" title="Products">
          <Books />
          <Contact2 />
        </Tab>

      </Tabs>



    </>
  );
};

export default Publishing;
