// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        home: "home",
        about: "About Us",
        solutions: "solutions",
        EducationalInstitutions: "Educational Institutions",
        EducationalCertificates: "Educational Certificates",
        printingAcademy: "Nahdet Misr printing Academy",
        appliedTechnologySchool: "Nahdet Misr Applied Technology School",
        internationalAcademy: "Ta'heal International Academy",
        projects: "Projects",
        sustainability: "Sustainability",
        DigitizationContent: "Digitization of Content",
        ProfessionalDevelopmentEducators: "Professional Development for Educators",
        news: "News",
        Products: "Products",
        leadership: "Leadership",
        careers: "Careers",
        contact: "Contact us",
        solutions: "Solutions",
        groupOverview: "Group Overview",
        subsidiaries: "Subsidiaries",
        curricula: "Content & Curricula Development",
        CurriculaDevelopmentlink: "Curricula Development",
        capability: "Capability Building",
        capabilityLink: "Capability Building for Educators",
        digital: "Digital Learning",
        software: "Software",
        vocational: "Vocational Education",
        investment: "Investment In Startups",
        publishing: "Publishing",
        copyright: "Nahdet Misr Group. © 2024. All Rights Reserved.",
        icon: "Designed and Developed by",
        Purpose: "Our Purpose",
        whatWeDo: "What We Do",
        whoWeServe: "Who We Serve",
        whatWeBringYou: "What We Bring You",
        ourValues: "Our Values",
        nahdetMistInFigures: "Nahdet Misr in Figures",
        markets: "Markets",
        awards: "Awards",
        aboutUsText:
          "We believe in the transformative power of lifelong learning and continuous skill development. At Nahdet Misr, we are dedicated to supporting you at every step of your journey towards brighter futures and more meaningful lives.",
        offeringText:
          "Through our wide variety of tailored services and products, we ensure the delivery of enhanced and advanced learning experiences that empower individuals.",
        educationalInstitutionsText:
          "We own and operate a number of vocational schools and academies that deliver first class educational experiences.",
        educationalInstitutions: "Educational Institutions",
        HistoryOurStory: "History – Our Story",
        contentDevelopment: "Content Development",
        translationLocalization: "Translation and Localization",
        trainingProgramsforEducators: "Training Programs for K-12 Educators",
        trainingProgramsforVocationalTutors: "Training Programs for Vocational Tutors",
        trainingProgramsEmployees: "Training Programs for Employees",
        videoProduction: "Video Production",
        interactiveLearning: "Interactive Learning",
        immersiveLearning: "Immersive Learning",
        books: "Books",
        magazines: "Magazines",
        printing: "Printing",
        digitalProducts: "Digital Products",
        booksInternationalCurriculaPrograms: "Books & International Curricula Programs",
        adwaaSeries: "Al Adwaa Series",
        AlAdwaa: "Al Adwaa",
        cambridgeUniversityPress: "Cambridge University Press",
        readersBooks: "Readers Books",
        schoolSupplies: "School Supplies",
        readMore: "Read More",
        UnitedStates: "United States",
        kuwait: "Kuwait",
        egypt: "Egypt",
        ksa: "KSA",
        uae: "UAE",
        oman: "Oman",
        djibouti: "Djibouti",
        DigitalStrategyConsultation: "Digital Strategy and Consultation",
        nahdetMisrAwards: "Nahdet Misr Awards",
        mapDesc:
          "Over time, Nahdet Misr has successfully established a robust global distribution network, encompassing over 12,000 distribution points. Additionally, we have expanded our operations to encompass various countries in the MENA and African regions, such as Saudi Arabia, the United Arab Emirates, Kuwait, Oman, and Djibouti.",
        switchLanguage: "Ar",
        ContentandCurriculaDevelopmentServices: "Content and Curricula Development Services",
        CapabilityBuildingProgramsServices: "Capability Building Programs Services",
        ContentCurriculaDevelopmentContact: "Let’s help you empower your learners with engaging curricula and content​",
        CurriculaDevelopmentServices: "Curricula Development Services",
        capabilityContact: "Let’s help you cater to the learning needs of your different customers",
        softwareDevelopmentContact:
          "We can help you enrich your digital content and remain at the forefront of technological advances​",
        DigitalLearningContact:
          "We can help you enrich your digital content and remain at the forefront of technological advances​",
        MobileApplicationsDevelopment: "Mobile Applications Development",
        investingStartupsContact: "Kickstart your business in edtech today and unlock your true potential.",
        vocationalEducationContact: "Realize your growth potential",
        PublishingSolutionsServices: "Publishing Solutions Services",
        publishingSolutionsContact: "Let’s help you cater to the learning needs of your different customers",
        OurTeamInclude: "Our team include:",
        DigitalLearningServices: "Digital Learning Services:",
        digitalLearningContact: "Let’s help you cater to the learning needs of your different customers",
        OurUltimateGoalIsToEmpowerEducationProfessionalsWithTheNecessaryToolsAndKnowledgeToEffectivelyManageTheirClassroomsAndSchoolsByEnhancingTheirSkillsWeAimToElevateTheOverallEducationalRxperienceForTheirStudents:
          "Our ultimate goal is to empower education professionals with the necessary tools and knowledge to effectively manage their classrooms and schools. By enhancing their skills, we aim to elevate the overall educational experience for their students.",
        WeEnsureThatEducatorsAreEquippedWithTheNecessarySkillsToDeliverEffectiveCurricula:
          "We ensure that educators are equipped with the necessary skills to deliver effective curricula.",
        ByEmpoweringEducatorsWithAdvancedTeachingMethodologiesTechnicalExpertiseAndPedagogicalSkillsWeEnableThemToProvideHighQualityVocationalTrainingThatMeetsIndustryStandardsAndPreparesStudentsForSuccessInTheWorkforce:
          "By empowering educators with advanced teaching methodologies, technical expertise, and pedagogical skills, we enable them to provide high-quality vocational training that meets industry standards and prepares students for success in the workforce.",
        OurProfessionalDevelopmentProgramsInclude: "Our professional development programs include:",
        k12: "K-12",
        LearningManagementSystems: "Learning Management Systems",
        services: "Services",
        RehlatieApplication: "Rehlatie Application",
        QessatieApplication: "Qessatie Application",
        InternationalCurriculaPrograms: "International Curricula & Programs",
        LeveledReaders: "Leveled  Readers",
        CalligraphyBooks: "Calligraphy Books",
        LifeSkillsCharacterDevelopment: "Life Skills and Character Development",
        EarlyChildhoodPrograms: "Early Childhood Programs",
        IslamicReligionCurricula: "Islamic Religion Curricula",
        SchoolSupplies: "School Supplies",
        HigherEducation: "Higher Education",
        elt: "ELT",
        GovernancePolicy: "Governance and Policy",
        QualityManagement: "Quality Management",
        TechnicalSchoolsManagementOperations: "Technical Schools Management and Operations",
        PrivatesectorEngagement: "Private sector Engagement",
        CapabilityBuildingVocationalEducators: "Capability Building for VocationalEducators",
        Corporates: "Corporates",
        DigitizationCorporateTrainingPrograms: "Digitization of Corporate Training Programs",
        CapabilityBuildingProgramsWorkforce: "Capability Building Programs for Workforce",
        TeachingArabicForeignLanguage: "Teaching Arabic as a Foreign Language",
        InvestingEdtechStartups: "Investing in Edtech Startups",
        nahdetMisrExamCenter: "Nahdet Misr Exam Center",
        MakingPositiveImpact: "Making a Positive Impact",
        ourSustainabilityProjects: "Our sustainability projects:",
        LetUsAssistYouInEnrichingYourArabicLanguageLearningResources: "Let us assist you in enriching your Arabic Language learning resources.",
        OurPartners: "Our Partners",
        socialImpact: "Social Impact",
        keyMilestones: "key milestones",
        ourProjects: "Our Projects",
        forFurtherInformationAndApplicationContact: "For further information and application contact:",
        NahdetMisrPrintingAcademy: "Nahdet Misr Printing Academy:",
        ForFurtherDetailsAndToInitiateAheApplicationProcessPlease:
          "For further details and to initiate the application process, please",
        visitTheLink: "visit the link.",
        Website: "Website",
        ForMoreInformationPleaseVisitNahdetMisrPublishingHouseExamCenter:
          "For more information please visit Nahdet Misr Publishing House Exam Center",
        emailAddress: "Email:",
        telAddress: "Tel:",
        ThereIsNothingToShow: "There is nothing to show",
        firstName: "First Name is required",
        lastName: "Last Name is required",
        email: "Email is required",
        comment: "Message category is required",
        jobTitle: "job Title is required",
        companyName: "company Name is required",
        country: "country is required",
        phoneNumber: "Phone number is required and must be numeric, with 10 to 12 digits.",
        phoneNumberNumeric: "Phone number must contain only numbers.",
        phoneNumberLength: "Phone number must be between 10 and 12 digits long.",
        GetinTouch: "Get in Touch",
        firstNamePlaceholder: "First Name",
        lastNamePlaceholder: "Last Name",
        emailPlaceholder: "Email",
        phoneNumberPlaceholder: "Phone Number",
        jobTitlePlaceholder: "Job Title",
        companyNamePlaceholder: "Company Name",
        countryPlaceholder: "Country",
        commentPlaceholder: "Comments",
        learnMore: "Learn More",
        ourLatestProjects: "Our Latest Projects",
        ournews: "our news",
        latestupdates: "Latest Updates",
        OurLatestAdditions: "Our Latest Additions",
        search: "search",
        submit: "Submit",
        newsTitle: "News & Press Releases",
        CorporatesServices: "Corporates Services",
        LetUsSupportYouInUpskillingAndEmpoweringYourWorkforce: "Let us support you in upskilling and empowering your workforce.",
        JoinUsInAdvancingEconomiesAndEmpoweringTheYoungerGenerationThroughVocationalEducation: "Let us assist you in advancing your learning experiences.",
        JoinUsInSpreadingKnowledgeAndShapingTheIntellectOfOurArabCommunities: "Join us in spreading knowledge and shaping the intellect of our Arab communities.",
        storeWebsite: "Store Website",
        DownloadEcommerceAppNowOrVisitOur: "Visit our online store or download our ecommerce app now",
        ExploreHowOurDiversifiedK12SolutionsCanEmpowerYourLearners: "Explore how our diversified K-12 solutions can empower your learners.",
        DownloadAlAdwaaAppNowOrVisitOur1: "Visit Al Adwaa ",
        DownloadAlAdwaaAppNowOrVisitOur2: "Download Al Adwaa app now ",
        DownloadQessatieAppNowOrVisit: "Download Qessatie app now ",
        DownloadRehlatieAppNowOrVisit: "Download Rehlatie app now "

      },
    },
    ar: {
      translation: {
        home: "home",
        about: "About Us",
        solutions: "solutions",
        EducationalInstitutions: "Educational Institutions",
        EducationalCertificates: "Educational Certificates",
        printingAcademy: "Nahdet Misr printing Academy",
        appliedTechnologySchool: "Nahdet Misr Applied Technology School",
        internationalAcademy: "Ta'heal International Academy",
        projects: "Projects",
        sustainability: "Sustainability",
        DigitizationContent: "Digitization of Content",
        ProfessionalDevelopmentEducators: "Professional Development for Educators",
        news: "News",
        Products: "Products",
        leadership: "Leadership",
        LetUsAssistYouInEnrichingYourArabicLanguageLearningResources: "Let us assist you in enriching your Arabic Language learning resources.",
        careers: "Careers",
        contact: "Contact us",
        solutions: "Solutions",
        groupOverview: "Group Overview",
        subsidiaries: "Subsidiaries",
        curricula: "Content & Curricula Development",
        CurriculaDevelopmentlink: "Curricula Development",
        capability: "Capability Building",
        capabilityLink: "Capability Building for Educators",
        digital: "Digital Learning",
        software: "Software",
        vocational: "Vocational Education",
        investment: "Investment In Startups",
        publishing: "Publishing",
        copyright: "Nahdet Misr Group. © 2024. All Rights Reserved.",
        icon: "Designed and Developed by",
        Purpose: "Our Purpose",
        whatWeDo: "What We Do",
        whoWeServe: "Who We Serve",
        whatWeBringYou: "What We Bring You",
        ourValues: "Our Values",
        nahdetMistInFigures: "Nahdet Misr in Figures",
        markets: "Markets",
        awards: "Awards",
        aboutUsText:
          "We believe in the transformative power of lifelong learning and continuous skill development. At Nahdet Misr, we are dedicated to supporting you at every step of your journey towards brighter futures and more meaningful lives.",
        offeringText:
          "Through our wide variety of tailored services and products, we ensure the delivery of enhanced and advanced learning experiences that empower individuals.",
        educationalInstitutionsText:
          "We own and operate a number of vocational schools and academies that deliver first class educational experiences.",
        educationalInstitutions: "Educational Institutions",
        HistoryOurStory: "History – Our Story",
        contentDevelopment: "Content Development",
        translationLocalization: "Translation and Localization",
        trainingProgramsforEducators: "Training Programs for K-12 Educators",
        trainingProgramsforVocationalTutors: "Training Programs for Vocational Tutors",
        trainingProgramsEmployees: "Training Programs for Employees",
        videoProduction: "Video Production",
        interactiveLearning: "Interactive Learning",
        immersiveLearning: "Immersive Learning",
        books: "Books",
        magazines: "Magazines",
        printing: "Printing",
        digitalProducts: "Digital Products",
        booksInternationalCurriculaPrograms: "Books & International Curricula Programs",
        adwaaSeries: "Al Adwaa Series",
        AlAdwaa: "Al Adwaa",
        cambridgeUniversityPress: "Cambridge University Press",
        readersBooks: "Readers Books",
        schoolSupplies: "School Supplies",
        readMore: "Read More",
        UnitedStates: "United States",
        kuwait: "Kuwait",
        egypt: "Egypt",
        ksa: "KSA",
        uae: "UAE",
        oman: "Oman",
        djibouti: "Djibouti",
        DigitalStrategyConsultation: "Digital Strategy and Consultation",
        nahdetMisrAwards: "Nahdet Misr Awards",
        mapDesc:
          "Over time, Nahdet Misr has successfully established a robust global distribution network, encompassing over 12,000 distribution points. Additionally, we have expanded our operations to encompass various countries in the MENA and African regions, such as Saudi Arabia, the United Arab Emirates, Kuwait, Oman, and Djibouti.",
        switchLanguage: "Ar",
        ContentandCurriculaDevelopmentServices: "Content and Curricula Development Services",
        CapabilityBuildingProgramsServices: "Capability Building Programs Services",
        ContentCurriculaDevelopmentContact: "Let’s help you empower your learners with engaging curricula and content​",
        CurriculaDevelopmentServices: "Curricula Development Services",
        capabilityContact: "Let’s help you cater to the learning needs of your different customers",
        softwareDevelopmentContact:
          "We can help you enrich your digital content and remain at the forefront of technological advances​",
        DigitalLearningContact:
          "We can help you enrich your digital content and remain at the forefront of technological advances​",
        MobileApplicationsDevelopment: "Mobile Applications Development",
        investingStartupsContact: "Kickstart your business in edtech today and unlock your true potential.",
        vocationalEducationContact: "Realize your growth potential",
        PublishingSolutionsServices: "Publishing Solutions Services",
        publishingSolutionsContact: "Let’s help you cater to the learning needs of your different customers",
        OurTeamInclude: "Our team include:",
        DigitalLearningServices: "Digital Learning Services:",
        digitalLearningContact: "Let’s help you cater to the learning needs of your different customers",
        OurUltimateGoalIsToEmpowerEducationProfessionalsWithTheNecessaryToolsAndKnowledgeToEffectivelyManageTheirClassroomsAndSchoolsByEnhancingTheirSkillsWeAimToElevateTheOverallEducationalRxperienceForTheirStudents:
          "Our ultimate goal is to empower education professionals with the necessary tools and knowledge to effectively manage their classrooms and schools. By enhancing their skills, we aim to elevate the overall educational experience for their students.",
        WeEnsureThatEducatorsAreEquippedWithTheNecessarySkillsToDeliverEffectiveCurricula:
          "We ensure that educators are equipped with the necessary skills to deliver effective curricula.",
        ByEmpoweringEducatorsWithAdvancedTeachingMethodologiesTechnicalExpertiseAndPedagogicalSkillsWeEnableThemToProvideHighQualityVocationalTrainingThatMeetsIndustryStandardsAndPreparesStudentsForSuccessInTheWorkforce:
          "By empowering educators with advanced teaching methodologies, technical expertise, and pedagogical skills, we enable them to provide high-quality vocational training that meets industry standards and prepares students for success in the workforce.",
        OurProfessionalDevelopmentProgramsInclude: "Our professional development programs include:",
        k12: "K-12",
        LearningManagementSystems: "Learning Management Systems",
        services: "Services",
        RehlatieApplication: "Rehlatie Application",
        QessatieApplication: "Qessatie Application",
        InternationalCurriculaPrograms: "International Curricula & Programs",
        LeveledReaders: "Leveled  Readers",
        CalligraphyBooks: "Calligraphy Books",
        LifeSkillsCharacterDevelopment: "Life Skills and Character Development",
        EarlyChildhoodPrograms: "Early Childhood Programs",
        IslamicReligionCurricula: "Islamic Religion Curricula",
        SchoolSupplies: "School Supplies",
        HigherEducation: "Higher Education",
        elt: "ELT",
        GovernancePolicy: "Governance and Policy",
        QualityManagement: "Quality Management",
        TechnicalSchoolsManagementOperations: "Technical Schools Management and Operations",
        PrivatesectorEngagement: "Private sector Engagement",
        CapabilityBuildingVocationalEducators: "Capability Building for VocationalEducators",
        Corporates: "Corporates",
        DigitizationCorporateTrainingPrograms: "Digitization of Corporate Training Programs",
        CapabilityBuildingProgramsWorkforce: "Capability Building Programs for Workforce",
        TeachingArabicForeignLanguage: "Teaching Arabic as a Foreign Language",
        InvestingEdtechStartups: "Investing in Edtech Startups",
        nahdetMisrExamCenter: "Nahdet Misr Exam Center",
        MakingPositiveImpact: "Making a Positive Impact",
        ourSustainabilityProjects: "Our sustainability projects:",
        OurPartners: "Our Partners",
        socialImpact: "Social Impact",
        keyMilestones: "key milestones",
        ourProjects: "Our Projects",
        forFurtherInformationAndApplicationContact: "For further information and application contact:",
        NahdetMisrPrintingAcademy: "Nahdet Misr Printing Academy:",
        ForFurtherDetailsAndToInitiateAheApplicationProcessPlease:
          "For further details and to initiate the application process, please",
        visitTheLink: "visit the link.",
        Website: "Website",
        ForMoreInformationPleaseVisitNahdetMisrPublishingHouseExamCenter:
          "For more information please visit Nahdet Misr Publishing House Exam Center",
        emailAddress: "Email:",
        telAddress: "Tel:",
        ThereIsNothingToShow: "There is nothing to show",
        firstName: "First Name is required",
        lastName: "Last Name is required",
        email: "Email is required",
        comment: "Message category is required",
        jobTitle: "job Title is required",
        companyName: "Company Name is required",
        country: "Country is required",
        phoneNumber: "Phone Number is required and must be numeric, with 10 to 12 digits.",
        phoneNumberNumeric: "Phone number must contain only numbers.",
        phoneNumberLength: "Phone number must be between 10 and 12 digits long.",
        GetinTouch: "Get in Touch",
        firstNamePlaceholder: "First Name",
        lastNamePlaceholder: "Last Name",
        emailPlaceholder: "Email",
        phoneNumberPlaceholder: "Phone Number",
        jobTitlePlaceholder: "Job Title",
        companyNamePlaceholder: "Company Name",
        countryPlaceholder: "country",
        commentPlaceholder: "Comments",
        learnMore: "Learn More",
        ourLatestProjects: "Our Latest Projects",
        ournews: "our news",
        latestupdates: "Latest Updates",
        OurLatestAdditions: "Our Latest Additions",
        search: "search",
        submit: "Submit"
      },
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
